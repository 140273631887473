/*
 *   Solve.Care Foundation OU ("COMPANY") CONFIDENTIAL
 *   Copyright © 2016 Solve.Care Foundation OU. All Rights Reserved.
 *
 *   NOTICE: All information contained herein is, and remains the property of COMPANY.
 *   The intellectual and technical concepts contained herein are proprietary to COMPANY
 *   and may be covered by European or foreign Patents, patents in process, and are
 *   protected by trade secret or copyright law.
 *   Dissemination of this information or reproduction of this material is strictly
 *   forbidden unless prior written permission is obtained from COMPANY.
 *   Access to the source code contained herein is hereby forbidden to anyone except
 *   current COMPANY employees, managers or contractors who have executed
 *   Confidentiality and Non-disclosure agreements explicitly covering such access.
 *
 *   The copyright notice above does not evidence any actual or intended publication
 *   or disclosure of this source code, which includes information that is confidential
 *   and/or proprietary, and is a trade secret, of COMPANY.
 *
 *   ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC  PERFORMANCE, OR
 *   PUBLIC DISPLAY OF OR THROUGH USE  OF THIS  SOURCE CODE  WITHOUT  THE EXPRESS
 *   WRITTEN CONSENT OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION  APPLICABLE
 *   LAWS AND INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF  THIS SOURCE CODE
 *   AND/OR RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE,
 *   DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING
 *   THAT IT  MAY DESCRIBE, IN WHOLE OR IN PART.
 */

// React imports
import React from 'react';
import { observer } from 'mobx-react-lite';
import PropTypes from 'prop-types';

// Material-UI imports
import { InputAdornment } from '@material-ui/core';
import { TextField } from '@CommonScene';
import SearchIcon from '@material-ui/icons/Search';
import CancelIcon from '@material-ui/icons/Cancel';

// Component Style imports
import useStyles from './styles';

const TableSearch = ({ placeholder, value, setQuery }) => {
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      <TextField
        id="search_query"
        placeholder={placeholder}
        value={value}
        onChange={e => setQuery(e.target.value)}
        InputProps={{
          classes: {
            input: classes.searchInput,
            underline: classes.overrideUnderline
          },
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon className={classes.icon} />
            </InputAdornment>
          ),
          endAdornment: value ? (
            <InputAdornment position="end">
              <CancelIcon
                id="clear-query"
                classes={{ root: classes.iconClose }}
                onClick={() => setQuery('')}
              />
            </InputAdornment>
          ) : null
        }}
      />
    </div>
  );
};

TableSearch.propTypes = {
  setQuery: PropTypes.func.isRequired,
  value: PropTypes.string,
  placeholder: PropTypes.string
};

TableSearch.defaultProps = {
  placeholder: 'Search'
};

export default observer(TableSearch);
