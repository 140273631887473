/*
 *   Solve.Care Foundation OU ("COMPANY") CONFIDENTIAL
 *   Copyright © 2016 Solve.Care Foundation OU. All Rights Reserved.
 *
 *   NOTICE: All information contained herein is, and remains the property of COMPANY.
 *   The intellectual and technical concepts contained herein are proprietary to COMPANY
 *   and may be covered by European or foreign Patents, patents in process, and are
 *   protected by trade secret or copyright law.
 *   Dissemination of this information or reproduction of this material is strictly
 *   forbidden unless prior written permission is obtained from COMPANY.
 *   Access to the source code contained herein is hereby forbidden to anyone except
 *   current COMPANY employees, managers or contractors who have executed
 *   Confidentiality and Non-disclosure agreements explicitly covering such access.
 *
 *   The copyright notice above does not evidence any actual or intended publication
 *   or disclosure of this source code, which includes information that is confidential
 *   and/or proprietary, and is a trade secret, of COMPANY.
 *
 *   ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC  PERFORMANCE, OR
 *   PUBLIC DISPLAY OF OR THROUGH USE  OF THIS  SOURCE CODE  WITHOUT  THE EXPRESS
 *   WRITTEN CONSENT OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION  APPLICABLE
 *   LAWS AND INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF  THIS SOURCE CODE
 *   AND/OR RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE,
 *   DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING
 *   THAT IT  MAY DESCRIBE, IN WHOLE OR IN PART.
 */

// Core
import React from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

// Material-UI
import { withStyles } from '@material-ui/core/styles';
import { Grid, Button } from '@material-ui/core';

// Components
import { PhoneNumberField, PasswordField } from '@CommonScene';
import { PrimaryButton } from '@CommonScene/materialUIComponents/PrimaryButton';
import HeaderTitle from '@AuthScene/scenes/common/HeaderTitle';
import ErrorSection from '@AuthScene/scenes/common/ErrorSection';

// Style
import styles from './styles';

// Utils
import { compose } from '@Utils';

export const LoginForm = ({
  classes,
  onFieldChange,
  handleChangeForm,
  handleSubmitForm,
  formData,
  errors,
  t,
  isSubmitBtnDisabled
}) => {
  const onChangeHandler = e =>
    onFieldChange(e.target.name, e.target.value, 'signInForm');

  return (
    <form onSubmit={handleSubmitForm} id="login-form">
      <HeaderTitle>{t('auth.login.loginHeading')}</HeaderTitle>

      <PhoneNumberField
        name="login"
        label={t('auth.login.loginHeading')}
        error={formData.fields.login.error}
        value={formData.fields.login.value}
        onChange={onChangeHandler}
        id="login-input"
      />
      <PasswordField
        name="password"
        inputProps={{ maxLength: 40 }}
        label={t('auth.login.passwordLabel')}
        value={formData.fields.password.value}
        error={formData.fields.password.error}
        onChange={onChangeHandler}
        id="password-input"
      />

      <ErrorSection errors={errors} />

      <Grid container classes={{ root: classes.buttonWrap }}>
        <Button
          classes={{ root: classes.forgotPass }}
          disableFocusRipple
          id="reset-password-btn"
          onClick={handleChangeForm}
          variant="text"
          color="primary"
        >
          {t('auth.login.remindPassword')}
        </Button>
      </Grid>

      <PrimaryButton
        type="submit"
        color="primary"
        id="login-btn"
        className={classes.button}
        disabled={!formData.meta.isValid || isSubmitBtnDisabled}
      >
        {t('auth.login.loginButton')}
      </PrimaryButton>
    </form>
  );
};

LoginForm.propTypes = {
  classes: PropTypes.object.isRequired,
  onFieldChange: PropTypes.func.isRequired,
  clearErrorMessage: PropTypes.func.isRequired,
  handleChangeForm: PropTypes.func.isRequired,
  handleSubmitForm: PropTypes.func.isRequired,
  formData: PropTypes.object.isRequired,
  errors: PropTypes.string,
  isSubmitBtnDisabled: PropTypes.bool,
  t: PropTypes.func.isRequired
};

LoginForm.defaultProps = {
  errors: undefined,
  isSubmitBtnDisabled: false
};

export default compose(
  withStyles(styles),
  withTranslation(),
  observer
)(LoginForm);
