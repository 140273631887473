/*
 *   Solve.Care Foundation OU ("COMPANY") CONFIDENTIAL
 *   Copyright © 2016 Solve.Care Foundation OU. All Rights Reserved.
 *
 *   NOTICE: All information contained herein is, and remains the property of COMPANY.
 *   The intellectual and technical concepts contained herein are proprietary to COMPANY
 *   and may be covered by European or foreign Patents, patents in process, and are
 *   protected by trade secret or copyright law.
 *   Dissemination of this information or reproduction of this material is strictly
 *   forbidden unless prior written permission is obtained from COMPANY.
 *   Access to the source code contained herein is hereby forbidden to anyone except
 *   current COMPANY employees, managers or contractors who have executed
 *   Confidentiality and Non-disclosure agreements explicitly covering such access.
 *
 *   The copyright notice above does not evidence any actual or intended publication
 *   or disclosure of this source code, which includes information that is confidential
 *   and/or proprietary, and is a trade secret, of COMPANY.
 *
 *   ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC  PERFORMANCE, OR
 *   PUBLIC DISPLAY OF OR THROUGH USE  OF THIS  SOURCE CODE  WITHOUT  THE EXPRESS
 *   WRITTEN CONSENT OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION  APPLICABLE
 *   LAWS AND INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF  THIS SOURCE CODE
 *   AND/OR RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE,
 *   DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING
 *   THAT IT  MAY DESCRIBE, IN WHOLE OR IN PART.
 */

// Core
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import classnames from 'classnames';

// Material UI
import { Grid, Typography, withStyles } from '@material-ui/core';

// Components
import { TableSearch } from '@CommonScene/MainContentWrapper/components/ContainerHeader/components';
import Filter from '@CommonScene/MainContentWrapper/components/Filter';

// Utils
import { compose } from '@Utils';

// Styles
import styles from './styles';

export class ContainerHeader extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    options: PropTypes.object.isRequired,
    setQuery: PropTypes.func,
    queryValue: PropTypes.string,
    filterOptions: PropTypes.object,
    tabsOptions: PropTypes.object
  };

  static defaultProps = {
    setQuery: undefined,
    queryValue: '',
    filterOptions: undefined,
    tabsOptions: {}
  };

  render() {
    const {
      classes,
      options: { title, isList, placeholder },
      setQuery,
      queryValue,
      filterOptions,
      tabsOptions: { tabs }
    } = this.props;

    return (
      <Grid container className={classes.wrapper}>
        <Grid item xs className={classes.titleWrapper}>
          <Grid
            container
            direction="column"
            justify="center"
            classes={{ root: classes.titles }}
          >
            <Typography id="title-text" variant="h5">
              {title}
            </Typography>
            {tabs
              ? tabs.map(tab => (
                  <Typography
                    key={tab.title}
                    variant="h6"
                    onClick={tab.onClick}
                    className={classnames(classes.tab, {
                      [classes.activeTab]: tab.isActive
                    })}
                  >
                    {tab.title}
                  </Typography>
                ))
              : null}
          </Grid>
        </Grid>

        <Grid item xs className={classes.searchBlock}>
          {isList && setQuery ? (
            <TableSearch
              id="table-search"
              setQuery={setQuery}
              value={queryValue}
              placeholder={placeholder}
            />
          ) : null}
          {filterOptions ? <Filter options={filterOptions} /> : null}
        </Grid>
      </Grid>
    );
  }
}

export default compose(
  withRouter,
  withStyles(styles),
  observer
)(ContainerHeader);
