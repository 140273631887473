/*
 *   Solve.Care Foundation OU ("COMPANY") CONFIDENTIAL
 *   Copyright © 2016 Solve.Care Foundation OU. All Rights Reserved.
 *
 *   NOTICE: All information contained herein is, and remains the property of COMPANY.
 *   The intellectual and technical concepts contained herein are proprietary to COMPANY
 *   and may be covered by European or foreign Patents, patents in process, and are
 *   protected by trade secret or copyright law.
 *   Dissemination of this information or reproduction of this material is strictly
 *   forbidden unless prior written permission is obtained from COMPANY.
 *   Access to the source code contained herein is hereby forbidden to anyone except
 *   current COMPANY employees, managers or contractors who have executed
 *   Confidentiality and Non-disclosure agreements explicitly covering such access.
 *
 *   The copyright notice above does not evidence any actual or intended publication
 *   or disclosure of this source code, which includes information that is confidential
 *   and/or proprietary, and is a trade secret, of COMPANY.
 *
 *   ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC  PERFORMANCE, OR
 *   PUBLIC DISPLAY OF OR THROUGH USE  OF THIS  SOURCE CODE  WITHOUT  THE EXPRESS
 *   WRITTEN CONSENT OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION  APPLICABLE
 *   LAWS AND INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF  THIS SOURCE CODE
 *   AND/OR RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE,
 *   DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING
 *   THAT IT  MAY DESCRIBE, IN WHOLE OR IN PART.
 */

// Core
import React from 'react';
import PropTypes from 'prop-types';

export { default as ConfigurationIcon } from '@Icons/Configuration_24x24.svg';
export {
  default as ParticipantsManagement
} from '@Icons/ParticipantsManagement_24x24.svg';
export { default as SendIcon } from '@Icons/Send_18x18.svg';
export { default as ResendIcon } from '@Icons/Resend_18x18.svg';
export { default as BlockIcon } from '@Icons/Block_18x18.svg';
export { default as UnblockIcon } from '@Icons/Unblock_18x18.svg';
export { default as DeleteIcon } from '@Icons/Delete_18x18.svg';
export { default as StatisticsIcon } from '@Icons/Statistics_24x24.svg';
export { default as ArrowDownBigIcon } from '@Icons/ArrowDownBig_18x18.svg';
export { default as CalendarIcon } from '@Icons/Calendar.svg';

export { default as ArrowBackIcon } from '@Icons/Back.svg';
export { default as PersonIcon } from '@Icons/Portrait.svg';
export { default as KeyboardArrowDownIcon } from '@Icons/ArrowDown.svg';
export { default as IconFilter } from '@Icons/Icon-Filter.svg';
export { default as UncheckedIcon } from '@Icons/Icon-Inactive-Selected.svg';
export { default as AbsentIcon } from '@Icons/IconAbsent.svg';

export { default as IconEye } from '@Icons/Icon-Show.svg';
export { default as IconEyeClosed } from '@Icons/Icon_Eye_Closed.svg';
export { default as IconWarning } from '@Icons/Icon-Warning.svg';
export { default as IconInfo } from '@Icons/Icon-Info.svg';

export const CheckedIcon = ({ color }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
    <rect
      x="1"
      y="1"
      width="22"
      height="22"
      rx="7"
      stroke="#A095AE"
      strokeWidth="2"
    />
    <rect width="24" height="24" rx="8" fill={color} />
    <path
      d="M18.3411 8.00024L11.1724 15.5318L6 10.1779"
      stroke="white"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

CheckedIcon.defaultProps = {
  color: '#9B51E0'
};

CheckedIcon.propTypes = {
  color: PropTypes.string
};
