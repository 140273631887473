/*
 *   Solve.Care Foundation OU ("COMPANY") CONFIDENTIAL
 *   Copyright © 2016 Solve.Care Foundation OU. All Rights Reserved.
 *
 *   NOTICE: All information contained herein is, and remains the property of COMPANY.
 *   The intellectual and technical concepts contained herein are proprietary to COMPANY
 *   and may be covered by European or foreign Patents, patents in process, and are
 *   protected by trade secret or copyright law.
 *   Dissemination of this information or reproduction of this material is strictly
 *   forbidden unless prior written permission is obtained from COMPANY.
 *   Access to the source code contained herein is hereby forbidden to anyone except
 *   current COMPANY employees, managers or contractors who have executed
 *   Confidentiality and Non-disclosure agreements explicitly covering such access.
 *
 *   The copyright notice above does not evidence any actual or intended publication
 *   or disclosure of this source code, which includes information that is confidential
 *   and/or proprietary, and is a trade secret, of COMPANY.
 *
 *   ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC  PERFORMANCE, OR
 *   PUBLIC DISPLAY OF OR THROUGH USE  OF THIS  SOURCE CODE  WITHOUT  THE EXPRESS
 *   WRITTEN CONSENT OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION  APPLICABLE
 *   LAWS AND INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF  THIS SOURCE CODE
 *   AND/OR RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE,
 *   DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING
 *   THAT IT  MAY DESCRIBE, IN WHOLE OR IN PART.
 */

// Core
import React from 'react';
import { observer } from 'mobx-react-lite';

// Material UI
import { Grid, Select, MenuItem, makeStyles } from '@material-ui/core';

// Components
import { FromToDateFilter } from '@CommonScene';

// Icons
import { ArrowDownBigIcon } from '@Utils/constans/icons';

// Utils
import { compose } from '@Utils';
import { useStore } from '@Utils/hooks';
import shortid from 'shortid';

const useStyles = makeStyles(theme => ({
  wrapper: {
    marginBottom: `${theme.spacing(2)}px`
  },
  nodeTypeSelectWrapper: {
    marginRight: `${theme.spacing(2)}px`
  },
  select: {
    color: '#A095AE',
    background: '#F2EEF6',
    lineHeight: '16px',
    border: '1px solid #E9E2F4',
    borderRadius: '17px',
    fontSize: '14px',
    '& > div': {
      padding: `${theme.spacing()}px 40px ${theme.spacing()}px ${theme.spacing(
        2
      )}px`,
      '&:focus': {
        background: 'none'
      }
    },
    '&:before': {
      display: 'none'
    },
    '&:after': {
      display: 'none'
    }
  },
  icon: {
    top: '8px',
    right: '15px'
  }
}));

const Filter = () => {
  const classes = useStyles();
  const {
    NodeActivityReportStore: {
      nodeTypes,
      nodeType,
      setNodeType,
      dateRange,
      getReport,
      resetDetails,
      setFilters,
      updateDateRange
    }
  } = useStore();

  const handleChangeNodeType = e => {
    setNodeType(e.target.value);
    getReport('UTILIZATION_LVL_1', null, true).then(() => setFilters(['role']));
  };

  const handleChangeDate = (date, key) => {
    resetDetails();
    let currentDate = date;
    if (!currentDate) {
      currentDate = key === 'from' ? 1546300800000 : Date.now();
    }
    updateDateRange({ [key]: currentDate });
    getReport('UTILIZATION_LVL_1', null, true).then(() => setFilters(['role']));
  };

  return (
    <Grid container alignItems="flex-end" className={classes.wrapper}>
      <Grid item className={classes.nodeTypeSelectWrapper}>
        <Select
          className={classes.select}
          value={nodeType}
          onChange={handleChangeNodeType}
          IconComponent={ArrowDownBigIcon}
          classes={{
            icon: classes.icon
          }}
        >
          {nodeTypes.map(type => (
            <MenuItem key={shortid.generate()} value={type.id}>
              {type.name}
            </MenuItem>
          ))}
        </Select>
      </Grid>
      <Grid item>
        <FromToDateFilter
          dateRange={dateRange}
          handleChangeDate={handleChangeDate}
        />
      </Grid>
    </Grid>
  );
};

export default compose(observer)(Filter);
