/*
 *   Solve.Care Foundation OU ("COMPANY") CONFIDENTIAL
 *   Copyright © 2016 Solve.Care Foundation OU. All Rights Reserved.
 *
 *   NOTICE: All information contained herein is, and remains the property of COMPANY.
 *   The intellectual and technical concepts contained herein are proprietary to COMPANY
 *   and may be covered by European or foreign Patents, patents in process, and are
 *   protected by trade secret or copyright law.
 *   Dissemination of this information or reproduction of this material is strictly
 *   forbidden unless prior written permission is obtained from COMPANY.
 *   Access to the source code contained herein is hereby forbidden to anyone except
 *   current COMPANY employees, managers or contractors who have executed
 *   Confidentiality and Non-disclosure agreements explicitly covering such access.
 *
 *   The copyright notice above does not evidence any actual or intended publication
 *   or disclosure of this source code, which includes information that is confidential
 *   and/or proprietary, and is a trade secret, of COMPANY.
 *
 *   ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC  PERFORMANCE, OR
 *   PUBLIC DISPLAY OF OR THROUGH USE  OF THIS  SOURCE CODE  WITHOUT  THE EXPRESS
 *   WRITTEN CONSENT OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION  APPLICABLE
 *   LAWS AND INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF  THIS SOURCE CODE
 *   AND/OR RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE,
 *   DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING
 *   THAT IT  MAY DESCRIBE, IN WHOLE OR IN PART.
 */

// Core
import React from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { withTranslation } from 'react-i18next';

// Components
import { MainContentWrapper } from '@CommonScene';
import TableWrapper from '@ParticipantsManagementScene/scenes/ParticipantsList/components/TableWrapper';

// Utils
import { compose } from '@Utils';
import { getParticipantManagementPath } from '@Utils/constans/paths';
import { participantStatuses } from '@Utils/constans';
import { setItemsChecked } from '@Utils/formatting';
import qs from 'query-string';

// Constants
import { CREATE_INVITATIONS } from '@Utils/constans/permissions';

export class ParticipantsList extends React.Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
    UserRolesStore: PropTypes.object.isRequired,
    InvitationStore: PropTypes.object.isRequired,
    FilterStore: PropTypes.object.isRequired,
    AuthStore: PropTypes.object.isRequired
  };

  componentDidMount() {
    this.getDataForFilters();
  }

  componentWillUnmount() {
    const {
      UserRolesStore: { reset: resetRoles },
      FilterStore: { reset: resetFilters },
      InvitationStore: { reset: resetInvitations }
    } = this.props;

    resetFilters();
    resetInvitations();
    resetRoles();
  }

  getDataForFilters = () => {
    const {
      UserRolesStore: { getRoles },
      FilterStore: { setFilters },
      InvitationStore: { getParticipants }
    } = this.props;

    const queryFilter = qs.parse(this.props.location.search);

    getRoles(null, 3000).then(response => {
      setFilters([
        {
          name: 'Status',
          items: setItemsChecked(participantStatuses, queryFilter.status)
        },
        {
          name: 'Roles',
          items: setItemsChecked(response.content, queryFilter.role)
        }
      ]);
      getParticipants();
    });
  };

  getHeaderOptions() {
    const { t } = this.props;

    return {
      title: t('mwAdmin.sidebar.participants'),
      placeholder: t('mwAdmin.invitations.searchByPhone'),
      isList: true
    };
  }

  getButtonOptions = () => {
    const {
      t,
      history,
      AuthStore: { permissions }
    } = this.props;

    return {
      isShown: permissions.includes(CREATE_INVITATIONS),
      text: t('mwAdmin.participants.newParticipant'),
      onClickHandler: () => {
        history.push(getParticipantManagementPath.newParticipants());
      }
    };
  };

  setDefaultQuery = () => {
    const {
      InvitationStore: { clearQuery, getParticipants }
    } = this.props;

    clearQuery();
    getParticipants();
  };

  applyFilter = () => {
    const {
      UserRolesStore: { getRoles },
      FilterStore: { resendRequestForFilters, setFilters, selectedFilters },
      InvitationStore: { getParticipants, resetPage }
    } = this.props;

    this.resetFilter();
    resetPage();
    getParticipants();
    if (resendRequestForFilters) {
      getRoles(null, 3000).then(response => {
        setFilters([
          {
            name: 'Status',
            items: setItemsChecked(participantStatuses, selectedFilters.Status)
          },
          {
            name: 'Roles',
            items: setItemsChecked(response.content, selectedFilters.Roles)
          }
        ]);
      });
    }
  };

  resetFilter = () => {
    const { history } = this.props;

    history.replace({
      search: ''
    });
  };

  getFilterOptions() {
    return {
      applyHandler: this.applyFilter,
      resetHandler: this.resetFilter
    };
  }

  render() {
    const { InvitationStore } = this.props;

    return (
      <MainContentWrapper
        headerOptions={this.getHeaderOptions()}
        buttonOptions={this.getButtonOptions()}
        setQuery={InvitationStore.setQuery}
        queryValue={InvitationStore.query}
        setDefaultQuery={this.setDefaultQuery}
        filterOptions={this.getFilterOptions()}
      >
        <TableWrapper />
      </MainContentWrapper>
    );
  }
}

export default compose(
  withTranslation(),
  inject('InvitationStore', 'AuthStore', 'UserRolesStore', 'FilterStore'),
  observer
)(ParticipantsList);
