/*
 *   Solve.Care Foundation OU ("COMPANY") CONFIDENTIAL
 *   Copyright © 2016 Solve.Care Foundation OU. All Rights Reserved.
 *
 *   NOTICE: All information contained herein is, and remains the property of COMPANY.
 *   The intellectual and technical concepts contained herein are proprietary to COMPANY
 *   and may be covered by European or foreign Patents, patents in process, and are
 *   protected by trade secret or copyright law.
 *   Dissemination of this information or reproduction of this material is strictly
 *   forbidden unless prior written permission is obtained from COMPANY.
 *   Access to the source code contained herein is hereby forbidden to anyone except
 *   current COMPANY employees, managers or contractors who have executed
 *   Confidentiality and Non-disclosure agreements explicitly covering such access.
 *
 *   The copyright notice above does not evidence any actual or intended publication
 *   or disclosure of this source code, which includes information that is confidential
 *   and/or proprietary, and is a trade secret, of COMPANY.
 *
 *   ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC  PERFORMANCE, OR
 *   PUBLIC DISPLAY OF OR THROUGH USE  OF THIS  SOURCE CODE  WITHOUT  THE EXPRESS
 *   WRITTEN CONSENT OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION  APPLICABLE
 *   LAWS AND INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF  THIS SOURCE CODE
 *   AND/OR RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE,
 *   DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING
 *   THAT IT  MAY DESCRIBE, IN WHOLE OR IN PART.
 */

// Core
import React, { useCallback, useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react-lite';

// Material UI
import { ClickAwayListener, makeStyles } from '@material-ui/core';

// Components
import FilterButton from '@CommonScene/MainContentWrapper/components/FilterButton';
import FilterModal from '@CommonScene/MainContentWrapper/components/FilterModal';

// Utils
import { compose } from '@Utils';
import { useStore } from '@Utils/hooks';

const useStyles = makeStyles(() => ({
  wrapper: {
    position: 'relative'
  }
}));

const getCounter = filters =>
  filters.reduce((acc, currentFilter) => {
    const checkedItems = currentFilter.items.filter(
      checkedItem => checkedItem.checked
    );
    return acc + checkedItems.length;
  }, 0);

const Filter = ({ options }) => {
  const [open, setOpen] = useState(false);
  const [initialFilterCounter, setInitialFilterCounter] = useState(0);
  const [initialSelectedFilters, setInitialSelectedFilters] = useState('');
  const classes = useStyles();

  const {
    FilterStore: {
      toggleStatusChecked,
      filters,
      filterCounter,
      deselectAllStatuses,
      selectAllStatuses,
      selectedFilters
    }
  } = useStore();

  useEffect(() => {
    setInitialFilterCounter(getCounter(filters));
  }, [filters.length]);

  useEffect(() => {
    setInitialSelectedFilters(JSON.stringify(selectedFilters));
  }, [filters.length]);

  const maxFilterCounter = useMemo(() => getCounter(filters), [filters.length]);

  const { applyHandler, resetHandler } = options;

  const handleClick = () => {
    setOpen(prev => !prev);
  };

  const handleClickAway = () => {
    setOpen(false);
  };

  const resetFilters = useCallback(() => {
    selectAllStatuses();

    if (resetHandler) {
      resetHandler();
    }
  }, [resetHandler]);

  const applyFilter = useCallback(() => {
    handleClick();
    applyHandler();
    setInitialFilterCounter(getCounter(filters));
    setInitialSelectedFilters(JSON.stringify(selectedFilters));
  }, [applyHandler, selectedFilters]);

  const disabledApplyBtn =
    !filterCounter ||
    initialSelectedFilters === JSON.stringify(selectedFilters);

  return (
    <div className={classes.wrapper}>
      <ClickAwayListener onClickAway={handleClickAway}>
        <div>
          <FilterButton
            onClickHandler={handleClick}
            filterCounter={filterCounter}
          />
          <FilterModal
            handleReset={resetFilters}
            filterCounter={filterCounter}
            initialFilterCounter={initialFilterCounter}
            maxFilterCounter={maxFilterCounter}
            handleApply={applyFilter}
            open={open}
            toggleStatusChecked={toggleStatusChecked}
            handleClear={deselectAllStatuses}
            filters={filters}
            disabledApplyBtn={disabledApplyBtn}
          />
        </div>
      </ClickAwayListener>
    </div>
  );
};

Filter.propTypes = {
  options: PropTypes.object.isRequired
};

export default compose(observer)(Filter);
