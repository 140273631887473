/*
 *   Solve.Care Foundation OU ("COMPANY") CONFIDENTIAL
 *   Copyright © 2016 Solve.Care Foundation OU. All Rights Reserved.
 *
 *   NOTICE: All information contained herein is, and remains the property of COMPANY.
 *   The intellectual and technical concepts contained herein are proprietary to COMPANY
 *   and may be covered by European or foreign Patents, patents in process, and are
 *   protected by trade secret or copyright law.
 *   Dissemination of this information or reproduction of this material is strictly
 *   forbidden unless prior written permission is obtained from COMPANY.
 *   Access to the source code contained herein is hereby forbidden to anyone except
 *   current COMPANY employees, managers or contractors who have executed
 *   Confidentiality and Non-disclosure agreements explicitly covering such access.
 *
 *   The copyright notice above does not evidence any actual or intended publication
 *   or disclosure of this source code, which includes information that is confidential
 *   and/or proprietary, and is a trade secret, of COMPANY.
 *
 *   ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC  PERFORMANCE, OR
 *   PUBLIC DISPLAY OF OR THROUGH USE  OF THIS  SOURCE CODE  WITHOUT  THE EXPRESS
 *   WRITTEN CONSENT OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION  APPLICABLE
 *   LAWS AND INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF  THIS SOURCE CODE
 *   AND/OR RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE,
 *   DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING
 *   THAT IT  MAY DESCRIBE, IN WHOLE OR IN PART.
 */

// Core
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer, inject } from 'mobx-react';
import { withTranslation } from 'react-i18next';

// Components
import { MainContentWrapper, DataTable, FromToDateFilter } from '@CommonScene';
import InfoBlock from '@StatisticsScene/scenes/common/InfoBlock';

// Material UI
import { withStyles } from '@material-ui/core';

// Config
import { tableConfig } from '@Assets/config/tables/nodeActivityDetails';

// Utils
import { compose, downloadPDF } from '@Utils';

// Constants
import { GENERATE_SUBSET_OF_DATASET } from '@Utils/constans/permissions';

// Styles
import styles from './styles';

export class NodeActivityDetails extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
    match: PropTypes.object.isRequired,
    BaseReportingStore: PropTypes.object.isRequired,
    NodeActivityDetailsReportStore: PropTypes.object.isRequired,
    AuthStore: PropTypes.object.isRequired,
    CommonStore: PropTypes.object.isRequired,
    FilterStore: PropTypes.object.isRequired
  };

  componentDidMount() {
    const {
      NodeActivityDetailsReportStore: { setPageParams, getReport, dateRange }
    } = this.props;
    setPageParams({
      node: this.props.match.params.nodeId,
      limit: 20,
      skip: 0,
      lastPage: false,
      ts_from: dateRange.from,
      ts_to: dateRange.to
    });
    getReport('UTILIZATION_LVL_2');
  }

  componentWillUnmount() {
    this.props.NodeActivityDetailsReportStore.resetDetails();
  }

  getHeaderOptions = () => ({
    title: this.props.t('can.statistics.nodeActivityDetails.title')
  });

  fetchTableData = () => {
    const {
      NodeActivityDetailsReportStore: {
        updatePageParams,
        pageParams,
        getReport,
        report,
        details
      }
    } = this.props;
    const aggregation = report.aggregation[0] || {};
    updatePageParams({
      skip: pageParams.skip + pageParams.limit,
      lastPage: aggregation.total === details.length
    });
    if (aggregation.total !== details.length) {
      getReport('UTILIZATION_LVL_2');
    }
  };

  handleChangeDate = (date, key) => {
    const {
      NodeActivityDetailsReportStore: {
        updateDateRange,
        getReport,
        resetDetails
      }
    } = this.props;

    resetDetails();
    let currentDate = date;
    if (!currentDate) {
      currentDate = key === 'from' ? 1546300800000 : Date.now();
    }
    updateDateRange({ [key]: currentDate });
    getReport('UTILIZATION_LVL_2', null, true);
  };

  getButtonOptions = () => {
    const {
      t,
      BaseReportingStore: { getPDFContent },
      NodeActivityDetailsReportStore: {
        pageParams,
        report: { aggregation }
      },
      FilterStore: { selectedFilters },
      AuthStore: { permissions },
      CommonStore: { pending }
    } = this.props;

    return {
      isShown: permissions.includes(GENERATE_SUBSET_OF_DATASET),
      isDisabled: pending,
      text: t('common.export'),
      onClickHandler: () =>
        getPDFContent('UTILIZATION_LVL_2', {
          ...pageParams,
          roles: selectedFilters.role,
          // Get all items without skipping
          skip: 0,
          limit: aggregation.reduce((acc, val) => val.total + acc, 0)
        }).then(() => {
          downloadPDF();
        })
    };
  };

  render() {
    const { classes, NodeActivityDetailsReportStore } = this.props;
    return (
      <MainContentWrapper
        headerOptions={this.getHeaderOptions()}
        buttonOptions={this.getButtonOptions()}
      >
        <FromToDateFilter
          handleChangeDate={this.handleChangeDate}
          dateRange={NodeActivityDetailsReportStore.dateRange}
        />
        <p className={classes.nodeId}>
          Node ID: {this.props.match.params.nodeId}
        </p>
        <InfoBlock
          totalLabel="Activities"
          data={NodeActivityDetailsReportStore.report.aggregation[0] || {}}
        />
        <DataTable
          tableConfig={tableConfig}
          tableData={NodeActivityDetailsReportStore.details}
          fetchTableData={this.fetchTableData}
          hasMoreData={!NodeActivityDetailsReportStore.pageParams.lastPage}
        />
      </MainContentWrapper>
    );
  }
}

export default compose(
  withTranslation(),
  withStyles(styles),
  inject(
    'BaseReportingStore',
    'NodeActivityDetailsReportStore',
    'AuthStore',
    'CommonStore',
    'FilterStore'
  ),
  observer
)(NodeActivityDetails);
