/*
 *   Solve.Care Foundation OU ("COMPANY") CONFIDENTIAL
 *   Copyright © 2016 Solve.Care Foundation OU. All Rights Reserved.
 *
 *   NOTICE: All information contained herein is, and remains the property of COMPANY.
 *   The intellectual and technical concepts contained herein are proprietary to COMPANY
 *   and may be covered by European or foreign Patents, patents in process, and are
 *   protected by trade secret or copyright law.
 *   Dissemination of this information or reproduction of this material is strictly
 *   forbidden unless prior written permission is obtained from COMPANY.
 *   Access to the source code contained herein is hereby forbidden to anyone except
 *   current COMPANY employees, managers or contractors who have executed
 *   Confidentiality and Non-disclosure agreements explicitly covering such access.
 *
 *   The copyright notice above does not evidence any actual or intended publication
 *   or disclosure of this source code, which includes information that is confidential
 *   and/or proprietary, and is a trade secret, of COMPANY.
 *
 *   ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC  PERFORMANCE, OR
 *   PUBLIC DISPLAY OF OR THROUGH USE  OF THIS  SOURCE CODE  WITHOUT  THE EXPRESS
 *   WRITTEN CONSENT OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION  APPLICABLE
 *   LAWS AND INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF  THIS SOURCE CODE
 *   AND/OR RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE,
 *   DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING
 *   THAT IT  MAY DESCRIBE, IN WHOLE OR IN PART.
 */

// Core
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import shortid from 'shortid';
import { observer } from 'mobx-react';

// Material UI
import { TableRow, TableCell, withStyles } from '@material-ui/core';

// Utils
import { renderCellContent } from '@Utils/formatting';
import { compose } from '@Utils';

// Components
import RowActions from '@CommonScene/DataTable/BodyRow/RowActions';
import { Checkbox } from '@CommonScene/index';
import { AbsentIcon } from '@Utils/constans/icons';

// Styles
import styles from './styles';

export class BodyRow extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    rowId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    extraId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    rowData: PropTypes.object.isRequired,
    rowCells: PropTypes.array.isRequired,
    hasActions: PropTypes.bool,
    hasCheckbox: PropTypes.bool,
    onCheckboxChange: PropTypes.func,
    actionsConfig: PropTypes.object,
    handleRowClick: PropTypes.func,
    history: PropTypes.object.isRequired
  };

  static defaultProps = {
    rowId: null,
    handleRowClick: undefined,
    hasActions: false,
    extraId: undefined,
    actionsConfig: {},
    onCheckboxChange: () => {
      // stub function
    }
  };

  getActionItems() {
    const { actionsConfig, rowData } = this.props;
    const { menuItems, filterMenuItems } = actionsConfig;
    if (filterMenuItems) {
      return filterMenuItems(rowData);
    }
    return menuItems || [];
  }

  render() {
    const {
      classes,
      rowData,
      rowId,
      extraId,
      handleRowClick,
      rowCells,
      hasActions,
      hasCheckbox,
      onCheckboxChange,
      history
    } = this.props;
    return (
      <TableRow
        id="row"
        className={classes.row}
        onClick={e => handleRowClick && handleRowClick(e, rowId, history.push)}
        style={{ cursor: handleRowClick ? 'pointer' : 'auto' }}
      >
        {hasCheckbox ? (
          <TableCell className={classes.checkboxCell}>
            <Checkbox
              onClick={e => e.stopPropagation()}
              onChange={() => onCheckboxChange(rowId)}
              checked={rowData.checked}
            />
          </TableCell>
        ) : null}
        {rowCells.map(cell => (
          // Row cells rendering
          <TableCell
            key={shortid.generate()}
            className={classes.cell}
            id="row-cell"
          >
            {renderCellContent(cell, rowData) || <AbsentIcon />}
          </TableCell>
        ))}
        {hasActions ? (
          // Actions cell rendering
          <TableCell className={classes.cell}>
            <RowActions
              id="row-actions"
              rowId={rowId}
              extraId={extraId}
              menuItems={this.getActionItems()}
            />
          </TableCell>
        ) : null}
      </TableRow>
    );
  }
}

export default compose(
  withStyles(styles),
  withRouter,
  observer
)(BodyRow);
