/*
 *   Solve.Care Foundation OU ("COMPANY") CONFIDENTIAL
 *   Copyright © 2016 Solve.Care Foundation OU. All Rights Reserved.
 *
 *   NOTICE: All information contained herein is, and remains the property of COMPANY.
 *   The intellectual and technical concepts contained herein are proprietary to COMPANY
 *   and may be covered by European or foreign Patents, patents in process, and are
 *   protected by trade secret or copyright law.
 *   Dissemination of this information or reproduction of this material is strictly
 *   forbidden unless prior written permission is obtained from COMPANY.
 *   Access to the source code contained herein is hereby forbidden to anyone except
 *   current COMPANY employees, managers or contractors who have executed
 *   Confidentiality and Non-disclosure agreements explicitly covering such access.
 *
 *   The copyright notice above does not evidence any actual or intended publication
 *   or disclosure of this source code, which includes information that is confidential
 *   and/or proprietary, and is a trade secret, of COMPANY.
 *
 *   ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC  PERFORMANCE, OR
 *   PUBLIC DISPLAY OF OR THROUGH USE  OF THIS  SOURCE CODE  WITHOUT  THE EXPRESS
 *   WRITTEN CONSENT OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION  APPLICABLE
 *   LAWS AND INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF  THIS SOURCE CODE
 *   AND/OR RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE,
 *   DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING
 *   THAT IT  MAY DESCRIBE, IN WHOLE OR IN PART.
 */

// Core
import React from 'react';
import PropTypes from 'prop-types';

// Material UI
import { Grid, withStyles } from '@material-ui/core';

// Utils
import { addSpacesInNumber } from '@Utils/formatting';
import { compose } from '@Utils';

// Styles
import styles from './styles';

const RoleLegend = ({ classes, data }) => {
  const member = data.find(item => item.role === 'Member role') || {};
  const physician = data.find(item => item.role === 'Physician') || {};
  const careCoordinator =
    data.find(item => item.role === 'Care Coordinator') || {};
  const practice = data.find(item => item.role === 'Practice') || {};

  return (
    <Grid item md={8} sm={12}>
      <Grid container className={classes.legend} alignItems="center">
        {member.total ? (
          <Grid item md={4} className={classes.MEMBER}>
            <span>{addSpacesInNumber(member.total)}</span>
            <p>{member.role}</p>
          </Grid>
        ) : (
          <Grid item md={4} />
        )}
        {physician.total ? (
          <Grid item md={4} className={classes.PHYSICIAN}>
            <span>{addSpacesInNumber(physician.total)}</span>
            <p>{physician.role}</p>
          </Grid>
        ) : (
          <Grid item md={4} />
        )}

        <Grid item md={4} />
        {careCoordinator.total ? (
          <Grid item md={4} className={classes.CARE_COORDINATOR}>
            <span>{addSpacesInNumber(careCoordinator.total)}</span>
            <p>{careCoordinator.role}</p>
          </Grid>
        ) : (
          <Grid item md={4} />
        )}
        {practice.total ? (
          <Grid item md={4} className={classes.PRACTICE}>
            <span>{addSpacesInNumber(practice.total)}</span>
            <p>{practice.role}</p>
          </Grid>
        ) : (
          <Grid item md={4} />
        )}
      </Grid>
    </Grid>
  );
};

RoleLegend.propTypes = {
  classes: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired
};

export default compose(withStyles(styles))(RoleLegend);
