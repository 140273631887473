// Core
import React, { Component } from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { Chart } from 'react-google-charts';

// Material UI
import { Grid, withStyles } from '@material-ui/core';

// Components
import { BlockWrapper } from '@CommonScene';

// Utils
import { compose } from '@Utils';
import { addSpacesInNumber } from '@Utils/formatting';

// Styles
import styles from './styles';

class PieChart extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    data: PropTypes.array.isRequired,
    nameKey: PropTypes.string.isRequired,
    valueKey: PropTypes.string.isRequired,
    Legend: PropTypes.oneOfType([
      PropTypes.func,
      PropTypes.object,
      PropTypes.array,
      PropTypes.node
    ]).isRequired
  };

  state = {
    isChartReady: false
  };

  render() {
    const { nameKey, valueKey, Legend } = this.props;

    const { classes, data } = this.props;

    const clonedData = [...data];

    const chartData = clonedData.map(item => {
      return [item[nameKey], Number(item[valueKey])];
    });

    const total = clonedData.reduce(
      (acc, val) => Number(val[valueKey]) + acc,
      0
    );

    const options = {
      pieSliceBorderColor: 'transparent',
      legend: 'none',
      pieHole: 0.7,
      slices: clonedData.map(item => {
        return {
          color: item.color
        };
      }),
      tooltip: {
        trigger: 'none'
      },
      pieSliceText: 'none',
      enableInteractivity: false,
      chartArea: {
        left: 0,
        top: 0,
        width: '100%',
        height: '100%'
      },
      fontName: 'Roboto'
    };

    return clonedData.length ? (
      <BlockWrapper withBottomMargin>
        <Grid container>
          <Grid item md={4} sm={12}>
            <Grid container justify="center">
              <Grid item className={classes.chartWrapper}>
                <Chart
                  chartType="PieChart"
                  data={[['item', 'value'], ...chartData]}
                  options={options}
                  width={'175px'}
                  height={'175px'}
                  chartEvents={[
                    {
                      eventName: 'ready',
                      callback: () => {
                        if (!this.state.isChartReady) {
                          this.setState({ isChartReady: true });
                        }
                      }
                    }
                  ]}
                />
                {this.state.isChartReady ? (
                  <div className={classes.totalCountWrapper}>
                    <span>{addSpacesInNumber(total)}</span>
                    <p>Total</p>
                  </div>
                ) : null}
              </Grid>
            </Grid>
          </Grid>
          <Legend data={clonedData} />
        </Grid>
      </BlockWrapper>
    ) : null;
  }
}

export default compose(
  withStyles(styles),
  observer
)(PieChart);
