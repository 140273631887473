/*
 *   Solve.Care Foundation OU ("COMPANY") CONFIDENTIAL
 *   Copyright © 2016 Solve.Care Foundation OU. All Rights Reserved.
 *
 *   NOTICE: All information contained herein is, and remains the property of COMPANY.
 *   The intellectual and technical concepts contained herein are proprietary to COMPANY
 *   and may be covered by European or foreign Patents, patents in process, and are
 *   protected by trade secret or copyright law.
 *   Dissemination of this information or reproduction of this material is strictly
 *   forbidden unless prior written permission is obtained from COMPANY.
 *   Access to the source code contained herein is hereby forbidden to anyone except
 *   current COMPANY employees, managers or contractors who have executed
 *   Confidentiality and Non-disclosure agreements explicitly covering such access.
 *
 *   The copyright notice above does not evidence any actual or intended publication
 *   or disclosure of this source code, which includes information that is confidential
 *   and/or proprietary, and is a trade secret, of COMPANY.
 *
 *   ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC  PERFORMANCE, OR
 *   PUBLIC DISPLAY OF OR THROUGH USE  OF THIS  SOURCE CODE  WITHOUT  THE EXPRESS
 *   WRITTEN CONSENT OF COMPANY IS STRICTLY PROHIBITED, AND IN VIOLATION  APPLICABLE
 *   LAWS AND INTERNATIONAL TREATIES.  THE RECEIPT OR POSSESSION OF  THIS SOURCE CODE
 *   AND/OR RELATED INFORMATION DOES NOT CONVEY OR IMPLY ANY RIGHTS TO REPRODUCE,
 *   DISCLOSE OR DISTRIBUTE ITS CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING
 *   THAT IT  MAY DESCRIBE, IN WHOLE OR IN PART.
 */

// Core
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { observer, inject } from 'mobx-react';
import shortid from 'shortid';

// Material UI
import { Grid, withStyles } from '@material-ui/core';

// Components
import { MainContentWrapper } from '@CommonScene';
import SummaryBlock from '@StatisticsScene/scenes/common/SummaryBlock';
import Card from '@StatisticsScene/scenes/CanTopologyStatistics/components/Card';

// Utils
import { compose, downloadPDF } from '@Utils';
import { groupBy } from 'lodash';
import { translation } from '@Utils/translation';
import { GENERATE_SUBSET_OF_DATASET } from '@Utils/constans/permissions';

// Styles
import styles from './styles';

export class CanTopologyStatistics extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
    BaseReportingStore: PropTypes.object.isRequired,
    TopologyReportStore: PropTypes.object.isRequired,
    AuthStore: PropTypes.object.isRequired,
    CommonStore: PropTypes.object.isRequired
  };

  componentDidMount() {
    const { TopologyReportStore } = this.props;
    TopologyReportStore.getReport('TOPOLOGY', {
      roles: [],
      statuses: []
    });
  }

  componentWillUnmount() {
    this.props.TopologyReportStore.resetDetails();
  }

  renderNodes = () => {
    const {
      classes,
      TopologyReportStore: { report }
    } = this.props;
    const nodesFromAggregation = groupBy(report.aggregation, 'type');
    const nodesFromAggregationKeys = Object.keys(nodesFromAggregation);
    const nodesFromDetails = groupBy(report.details, 'type');

    const getNodeRolesKeys = nodeName =>
      Object.keys(groupBy(nodesFromDetails[nodeName], 'role'));

    const sortingTemplate = ['CREATED', 'ASSIGNED_IDENTITY', 'ASSIGNED_WALLET'];

    const getSortedItems = items =>
      sortingTemplate.map(
        status =>
          items.find(item => item.status === status) || { status, total: 0 }
      );

    return (
      <Fragment>
        {nodesFromAggregationKeys.map(nodeName => (
          <Fragment key={shortid.generate()}>
            <p className={classes.nodeName}>
              {translation(nodeName, 'nodesNames')}
            </p>
            <SummaryBlock
              items={getSortedItems(nodesFromAggregation[nodeName])}
            />
            <Grid container spacing={2} className={classes.cardsWrapper}>
              {getNodeRolesKeys(nodeName).map(roleName => (
                <Card
                  key={shortid.generate()}
                  title={roleName}
                  nodeItems={getSortedItems(
                    groupBy(nodesFromDetails[nodeName], 'role')[roleName]
                  )}
                />
              ))}
            </Grid>
          </Fragment>
        ))}
        <p className={classes.nodeName}>
          {translation('ENDPOINT', 'nodesNames')}
        </p>
        <Grid container spacing={2} className={classes.cardsWrapper}>
          {getNodeRolesKeys('ENDPOINT').map(roleName => (
            <Card
              key={shortid.generate()}
              title={roleName}
              nodeItems={groupBy(nodesFromDetails.ENDPOINT, 'role')[roleName]}
            />
          ))}
        </Grid>
      </Fragment>
    );
  };

  getButtonOptions = () => {
    const {
      t,
      BaseReportingStore: { getPDFContent },
      TopologyReportStore: { pageParams, details },
      AuthStore: { permissions },
      CommonStore: { pending }
    } = this.props;

    return {
      isShown: permissions.includes(GENERATE_SUBSET_OF_DATASET),
      isDisabled: pending,
      text: t('common.export'),
      onClickHandler: () =>
        getPDFContent('TOPOLOGY', {
          ...pageParams,
          skip: 0,
          limit: details.length,
          roles: [],
          statuses: []
        }).then(() => {
          downloadPDF();
        })
    };
  };

  render() {
    const { t } = this.props;
    const HEADER_OPTIONS = {
      title: t('can.sidebar.canTopologyStatistics')
    };

    return (
      <MainContentWrapper
        headerOptions={HEADER_OPTIONS}
        buttonOptions={this.getButtonOptions()}
      >
        {this.renderNodes()}
      </MainContentWrapper>
    );
  }
}

export default compose(
  withStyles(styles),
  withTranslation(),
  inject(
    'BaseReportingStore',
    'TopologyReportStore',
    'AuthStore',
    'CommonStore'
  ),
  observer
)(CanTopologyStatistics);
